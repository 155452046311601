<template>
  <div class="editList">
    <div style="background: #fff;padding: 30px;" class="form-w-320">
      <el-form ref="form" :model="form" :rules="rules" label-width="115px">
        <titleDom title="账号信息"/>
        <div class="itemBox">
          <el-form-item label="系统账号" prop="account">
            <el-input v-model="form.account" placeholder="请填写员工登录系统账号" style="width: 320px"
                      :disabled="!isAdd"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input type="password" v-model="form.password" placeholder="请填写员工登录系统密码，至少6位数"
                      style="width: 320px" :disabled="!isAdd"></el-input>
            <span class="resetPwd" v-if="!isAdd" @click="resetPwd()">重置密码</span>
          </el-form-item>
          <el-form-item label="角色" prop="role_id" class="item-width-1200">
            <el-checkbox-group v-model="form.role_id" @change="changeRoles" style="width: 1200px">
              <el-checkbox v-for="item in dictData.roles" :label="item.id" :key="item.id"> {{
                  item.value
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="账号状态" prop="status">
            <el-radio-group v-model="form.status" @change="changeStatus">
              <el-radio v-for="item in dictData.status" :label="item.id" :key="item.id"> {{ item.value }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <titleDom title="员工信息"/>
        <div class="itemBox column2 clearfix">
          <div>
            <el-form-item label="员工姓名" prop="full_name">
              <el-input v-model="form.full_name" placeholder="请填写员工姓名" style="width: 320px"
                        ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="所属组织" prop="organize_id">
<!--              <el-select v-model="form.organize_id" placeholder="请选择所属组织" style="width: 320px"-->
<!--                         :popper-append-to-body="false">-->
<!--                <el-option v-for="item in dictData.dept" :label="item.value" :value="item.id"-->
<!--                           :key="item.id"></el-option>-->
<!--              </el-select>-->
              <treeselect
                  placeholder="请选择所属组织" style="width: 320px"
                  v-model="form.organize_id"
                  :options="dictData.dept"
                  :normalizer="normalizer"
                  :show-count="true"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item label="工号" prop="job_num">
              <el-input v-model="form.job_num" placeholder="请填写工号" style="width: 320px"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="岗位" prop="position">
              <el-input v-model="form.position" placeholder="请填写岗位" style="width: 320px"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="联系电话" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请填写联系电话" style="width: 320px"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="出生日期" prop="birthday">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.birthday" class="date"
                              suffix-icon="el-icon-data"
                              value-format="yyyy-MM-dd"
                              style="width: 320px;"></el-date-picker>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="年龄" prop="age">
              <el-input v-model="form.age" placeholder="请填写年龄" style="width: 320px" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="form.sex" @change="changeStatus">
                <el-radio v-for="item in dictData.sex" :label="item.id" :key="item.id"> {{ item.value }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="学历" prop="education">
              <el-select v-model="form.education" placeholder="请选择学历" style="width: 320px"
                         :popper-append-to-body="false">
                <el-option v-for="item in dictData.education" :label="item.value" :value="item.id"
                           :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="在职状态" prop="job_status">
              <el-radio-group v-model="form.job_status" @change="changeStatus">
                <el-radio v-for="item in dictData.job_status" :label="item.id" :key="item.id"> {{
                    item.value
                  }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
        <el-form-item>
          <div style="background: #fff;" class="btn_box">
            <a class="blue" href="#" @click="submit">确定</a>
            <a class="" href="#" @click="returnToPage">返回</a>
          </div>
        </el-form-item>
      </el-form>


    </div>


  </div>
</template>

<script>

import titleDom from "@/components/common/titleDom.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const defaultForm = {
  id: null,
  organize_id: null,
  role_id: [],
  full_name: '',
  account: '',
  password: '',
  job_num: '',
  mobile: '',
  position: '',
  birthday: '',
  sex: 1,
  age: '',
  education: '',
  job_status: 1,
  status: 1,
}
export default {
  name: "editList",
  components: {Treeselect, titleDom},
  data() {
    return {
      queryID: null,
      page: null,
      // 选项字典data
      dictData: {
        roles: [],
        // 1=启用 2=禁用
        status: [
          {
            id: 1,
            value: '启用'
          },
          {
            id: 2,
            value: '禁用'
          }
        ],
        dept: [],
        // 1=男 2=女 3=保密
        sex: [
          {
            id: 1,
            value: '男'
          },
          {
            id: 2,
            value: '女'
          },
          {
            id: 3,
            value: '保密'
          }
        ],
        education: [
          {
            id: '博士研究生',
            value: '博士研究生'
          },
          {
            id: '硕士研究生',
            value: '硕士研究生'
          },
          {
            id: '本科',
            value: '本科'
          },
          {
            id: '专科及以下',
            value: '专科及以下'
          }
        ],
        // 1=在职在岗 2=在职离岗 3=离职
        job_status: [
          {
            id: 1,
            value: '在职在岗'
          },
          {
            id: 2,
            value: '在职离岗'
          },
          {
            id: 3,
            value: '离职'
          }
        ]
      },
      form: {
        ...defaultForm,
      },
      rules: {
        account: [
          {required: true, message: '请输入系统账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入登录密码', trigger: 'blur'},
          {pattern: /^[a-zA-Z\d#@!~%^&*.]{6,}$/, message: "员工登录系统密码，至少6位数", trigger: "blur"}
        ],
        role_id: [
          {required: true, message: '请选择角色', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '请选择账号状态', trigger: 'blur'},
        ],
        full_name: [
          {required: true, message: '请输入员工姓名', trigger: 'blur'},
        ],
        organize_id: [
          {required: true, message: '请选择所属组织', trigger: 'blur'},
        ],
        job_num: [
          {required: true, message: '请输入工号', trigger: 'blur'},
        ],
        position: [
          {required: true, message: '请输入岗位', trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {pattern: /^1[3-9]\d{9}$/, message: "请输入正确的联系电话", trigger: "blur"}
        ]
      }
    }
  },
  computed: {
    isAdd: function () {
      return !this.$route.query.id;
    }
  },
  watch: {
    'form.birthday': function (newVal) {
      if (newVal && newVal !== '0000-00-00 00:00:00') {
        this.form.age = this.calculateAge(newVal);
      } else {
        this.form.age = null;
      }
    },
    'form.role_id': function (newVal, oldVal) {
      const newb = newVal.includes(2);
      const oldb = oldVal.includes(2);
      if (oldb) {
        if (!(newVal.length === 1 && newVal[0] === 2)) {
          this.form.role_id = newVal.filter(e => e !== 2);
        }
      } else if (newb) {
        this.form.role_id = [2]
      }
    }
  },
  mounted() {
    this.queryID = this.$route.query.id;
    this.page = this.$route.query.page;
    if (this.queryID) {
      this.getUser(this.$route.query.id);
    }
    this.getRoleOption();
    this.getOrganizeOption(1);
  },
  methods: {
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    getRoleOption() {
      this.$api.getRoleOption().then(res => {
        const data = res.data.map(e => {
          return {
            id: e.id,
            value: e.role_name
          }
        })
        this.$set(this.dictData, 'roles', data)
      })
    },
    getOrganizeOption(type) {
      this.$api.getOrganizeTreeList(type).then(res => {
        this.$set(this.dictData, 'dept', res.data)
      })
    },
    getUser(id) {
      this.$api.getUser(id).then(res => {
        this.form = res.data;
      })
    },
    changeRoles(val) {
      console.log(val, 111)
    },
    changeStatus(val) {
      console.log(val, 111)
    },
    // 重置密码
    resetPwd() {
      this.$confirm('此操为敏感操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.resetPwd({id: this.queryID}).then(() => {
          this.$message({
            type: 'success',
            message: '重置密码成功!'
          });
          this.getDataList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码'
        });
      });
    },
    // 根据出生日期计算年龄
    calculateAge(birthDateString) {
      console.log(birthDateString, 1111)
      const today = new Date();
      const birthDate = new Date(birthDateString);
      const ageInMilliseconds = today.getTime() - birthDate.getTime();
      const ageInYears = Math.floor(ageInMilliseconds / (365 * 24 * 60 * 60 * 1000));
      return ageInYears;
    },
    // 表单重置
    reset() {
      this.form = {
        ...defaultForm,
        id: this.queryID
      };
      this.resetForm("form");
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$api.editUser(this.form).then(() => {
            this.$message({
              message: this.form.id ? "修改成功" : "新增成功",
              type: 'success'
            });
            this.reset();
            this.$router.push({name: 'User', params: {page: this.page}});
          });
        }
      });
    },
    returnToPage() {
      this.$router.push({name: "User", params: {page: this.page}});
    }
  },
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.editList {
  margin: 18px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  overflow: scroll;
  scrollbar-width: none;
  background: #fff;

  // 表单宽度为320px样式修改
  .form-w-320 {
    // 表单item样式修改
    ::v-deep .el-form-item.item-width-1200 {

      .el-form-item__error {
        top: 80%;
        left: 0;
      }
    }
  }

  ::v-deep .vue-treeselect {
    height: 34px;
    line-height: 34px;
    background: #FFFFFF;
    border-radius: 4px;

    .vue-treeselect {
      border: 1px solid #CEDAE0;
    }
  }

  .resetPwd {
    width: 48px;
    height: 11px;
    font-weight: 400;
    font-size: 12px;
    color: #3984F5;
    line-height: 36px;
    position: relative;
    left: 12px;
    cursor: pointer;
  }

  .itemBox {
    margin-top: 28px;
  }

  .column2 {

    > div {
      height: 41px;
      float: left;
      margin-bottom: 8px;
    }

    ::v-deep .el-form-item {
      width: 650px;
    }

  }

  .btn_box {
    margin-top: 18px;

    a {
      display: inline-block;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #EEF1F3;
      border-radius: 4px;
      margin-right: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #6C7880;
    }

    .blue {
      background: #3984F5;
      color: #fff;
    }
  }
}
</style>